<template>
  <div class="row mb-3 mt-2">
    <div
      class="sd-avatar-image-field "
    >
      <croppa
        v-model="img"
        canvas-color="transparent"
        :show-remove-button="false"
        :disabled="Boolean(src && !uiFlags.isRemoveImg)"
        :disable-drag-to-move="true"
        :disable-scroll-to-zoom="true"
        :disable-pinch-to-zoom="true"
        :prevent-white-space="true"
        crossorigin="anonymous"
        :initial-image="src"
        :file-size-limit="fileSizeLimitInByte"
        initial-size="contain"
        :height="64"
        :width="64"
        :quality="2"
        accept="image/png,image/jpeg,image/jpg"
        placeholder=""
        @new-image-drawn="imgChange"
        @file-type-mismatch="fileTypeMismatch"
        @file-size-exceed="fileSizeExceed"
      >
        <div class="default-slot">
          <div
            v-if="img && !img.hasImage()"
            class="row no-gutters"
          >
            <div class="empty-avatar-wrapper">
              <i class="sdicon-plus plus-icon" />
            </div>
          </div>
        </div>
      </croppa>
    </div>
    <div class="ml-4 align-self-center">
      <span class="d-flex font-17">
        Profile Picture
      </span>
      <span
        v-if="img && img.hasImage()"
        class="pointer d-flex text-gray-dark font-15"
        @click="removeImg"
      >
        <br><u>Remove</u>
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';

import { showErrorNotify } from '../../utils/NotifyUtil';

const fileSizeLimit = 4; // in MB
const imageOutputType = 'png';
const imageOutputQuality = 0.8;
const fileSizeLimitInByte = fileSizeLimit * 1024 * 1024;
export default {
  name: 'ProfileAvatarUploadField',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const img = ref(null);
    const uiFlags = reactive({ isRemoveImg: false, isInitialLoad: true });
    return {
      img,
      uiFlags,
      removeImg,
      imgChange,
      fileTypeMismatch,
      fileSizeExceed,
      fileSizeLimitInByte,
    };

    function imgChange() {
      const hasImg = img.value && img.value.hasImage();
      if (props.src && uiFlags.isInitialLoad) {
        uiFlags.isInitialLoad = false;
        return;
      }
      if (hasImg) {
        img.value.generateBlob((blob) => {
          context.emit('set-image-field', blob);
        }, imageOutputType, imageOutputQuality);
      }
    }

    function removeImg() {
      uiFlags.isInitialLoad = false;
      uiFlags.isRemoveImg = true;
      img.value.remove();
    }

    function fileTypeMismatch() {
      showErrorNotify(context, 'Picture must be JPG, JPEG or PNG format');
    }
    function fileSizeExceed() {
      showErrorNotify(context, `Picture size can not exceed ${fileSizeLimit}MB`);
    }
  },
};
</script>

<style lang="scss">
    .sd-avatar-image-field {
        background-color: transparent;
        .empty-avatar-wrapper{
            display: flex;
            justify-content: center;
            background-color: gray();
            height: 64px;
            width: 64px;
            border-radius: 50%;
            .plus-icon{
                align-self: center;
                color: gray-color('dark');
                font-size: $--font-size-medium-large;
            }
        }
        canvas{
            border-radius: 50%;
        }
        .croppa-container{
            cursor: pointer;
            border-radius: $app-border-radius;
            &.croppa--has-target {
            cursor: move;
            }
            &.croppa--disabled {
                cursor: default;
            }
        }
        .default-slot {
            border-radius: $app-border-radius;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            pointer-events: none;
            .drag-and-scroll{
                text-shadow: 1px 1px 1px #000000;
            }
        }
    }
</style>
