const BASE_NYLAS_URL = 'https://api.us.nylas.com/v3/connect/auth';

function nylasCalendarAuthLink(context) {
  const user = context.root.$store.state.Auth.user;
  const baseUrl = window.location.origin;

  const paramObj = {
    client_id: process.env.VUE_APP_NYLAS_CLIENT_ID,
    scopes: 'calendar,calendar.free_busy',
    response_type: 'code',
    redirect_uri: `${baseUrl}/profile/settings`,
    state: user.id,
    prompt: 'select_provider',
    redirect_on_error: true,
  };

  const urlParams = new URLSearchParams(paramObj).toString();

  return `${BASE_NYLAS_URL}?${urlParams}`;
}

export { nylasCalendarAuthLink };
