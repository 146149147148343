<template>
  <SdPage
    id="settings-page"
    :class="$viewport.lt.md ? 'mobile':''"
  >
    <ConfirmRemoveCalendarIntegrationDialog
      ref="confirmRemoveCalendarIntegrationDialog"
      @confirm="confirmDisconnectCalendar"
    />
    <SdPageHeader title="Profile Settings" />
    <div class="row settings-page-row">
      <div class="col-12 col-lg-8">
        <h2 v-if="$viewport.gt.md">
          Personal Details
        </h2>
        <div
          class="col "
          :class="$viewport.lt.md ? 'mb-4':''"
        >
          <ProfileAvatarUploadField
            :src="user.avatar_file_url"
            @set-image-field="uploadUserAvatar"
          />
        </div>
        <ElForm
          ref="form"
          :model="payload"
          :rules="rulesRef"
          class="row align-items-center"
          @submit.native.prevent="updateUser"
        >
          <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
            <el-tabs
              v-model="activeTabRef"
              :stretch="true"
            >
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-touch:swipe="swipeHandler"
                  label="Personal"
                  class="row"
                >
                  <div class="col-12 col-md-6">
                    <ElFormItem prop="first_name">
                      <SdFloatLabel for-input="first_name">
                        <ElInput
                          id="first_name"
                          v-model="payload.first_name"
                          placeholder="First Name"
                        />
                      </SdFloatLabel>
                    </ElFormItem>
                  </div>
                  <div class="col-12 col-md-6">
                    <ElFormItem prop="last_name">
                      <SdFloatLabel for-input="last_name">
                        <ElInput
                          id="last_name"
                          v-model="payload.last_name"
                          placeholder="Last Name"
                        />
                      </SdFloatLabel>
                    </ElFormItem>
                  </div>
                  <div class="col-12 col-md-6">
                    <ElFormItem prop="public_phone_number">
                      <SdFloatLabel
                        for-input="public_phone_number"
                        with-optional
                      >
                        <el-tooltip
                          :visible-arrow="false"
                          :disabled="$viewport.lt.md"
                          popper-class="popover-panel"
                          placement="right-start"
                        >
                          <template slot="content">
                            <i class="sdicon-info-circle" />
                            <p>If entered, this number will be included in listings for prospects to contact you</p>
                          </template>
                          <ElInput
                            id="public_phone_number"
                            v-model="payload.public_phone_number"
                            class="input-with-append"
                            placeholder="Listing phone number"
                          >
                            <SdFloatLabel
                              slot="append"
                              for-input="public_phone_number_extension"
                            >
                              <ElFormItem prop="public_phone_number_extension">
                                <ElInput
                                  id="public_phone_number_extension"
                                  v-model="payload.public_phone_number_extension"
                                  class="input-phone-extension"
                                  type="text"
                                  placeholder="Ext"
                                />
                              </ElFormItem>
                            </SdFloatLabel>
                          </ElInput>
                        </el-tooltip>
                      </SdFloatLabel>
                    </ElFormItem>
                  </div>
                  <div class="col-12 col-md-6">
                    <SdFloatLabel for-input="phone_number">
                      <ElFormItem prop="phone_number">
                        <el-tooltip
                          :visible-arrow="false"
                          :disabled="$viewport.lt.md"
                          popper-class="popover-panel"
                          placement="right-start"
                        >
                          <template slot="content">
                            <i class="sdicon-info-circle" />
                            <p>
                              This number will be used by Showdigs for all notifications and should receive SMS
                              messages
                            </p>
                          </template>
                          <ElInput
                            id="phone_number"
                            v-model="payload.phone_number"
                            class="input-with-append"
                            placeholder="Phone number"
                          >
                            <SdFloatLabel
                              slot="append"
                              for-input="phone_number_extension"
                            >
                              <ElFormItem prop="phone_number_extension">
                                <ElInput
                                  id="phone_number_extension"
                                  v-model="payload.phone_number_extension"
                                  class="input-phone-extension"
                                  type="text"
                                  placeholder="Ext"
                                  readonly
                                  onfocus="this.removeAttribute('readonly');"
                                />
                              </ElFormItem>
                            </SdFloatLabel>
                          </ElInput>
                        </el-tooltip>
                      </ElFormItem>
                    </SdFloatLabel>
                  </div>
                  <div class="col-12 col-md-6">
                    <ElFormItem prop="email">
                      <SdFloatLabel for-input="email">
                        <ElInput
                          id="email"
                          v-model="payload.email"
                          disabled
                          placeholder="Email"
                        />
                      </SdFloatLabel>
                    </ElFormItem>
                  </div>
                </el-tab-pane>
              </SdConditionallyBumpChildren>
              <div class="col-12 d-md-flex d-none">
                <ElDivider />
              </div>
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-touch:swipe="swipeHandler"
                  label="Calendar"
                  class="row"
                >
                  <div
                    v-if="$viewport.gt.md"
                    class="col-12"
                  >
                    <h2>
                      Calendar
                    </h2>
                  </div>
                  <template v-if="$store.getters['Auth/hasInitialCalendarConnection']">
                    <div class="col-12 col-md-8">
                      Your calendar is now connected to your Showdigs account.<br>
                      This will be used to help ensure tours are not scheduled for you
                      when your calendar shows that you are busy.
                    </div>
                    <div
                      class="col-12 col-md-2"
                      :class="$viewport.lt.md ? 'p-3' : ''"
                    >
                      <ElButton
                        type="primary"
                        :class="$viewport.lt.md ? 'w-100' : ''"
                        :size="$viewport.lt.md ? '' : 'medium'"
                        :disabled="calendarWorking"
                        :loading="calendarWorking"
                        @click="disconnectCalendar"
                      >
                        <i class="sdicon-calendar-times-o" />
                        Disconnect
                      </ElButton>
                    </div>
                    <div
                      v-if="$store.getters['Calendar/availableCalendarsOptionsLength']"
                      class="col-md-8 col-12"
                    >
                      You can change which calendars to use to check for your busy times:
                      <div class="row font-15 justify-content-center">
                        <div class="col-1">
                          <SdSpinner
                            v-if="calendarData.working"
                            class="position-absolute mt-4 working"
                          />
                        </div>
                      </div>
                      <ElSelect
                        v-model="state.Calendar.selectedCalendars"
                        :disabled="calendarData.working"
                        multiple
                        placeholder="All Calendars"
                        class="light-background"
                        @change="onCalendarSelectionUpdate"
                      >
                        <ElOption
                          v-for="calendar in $store.getters['Calendar/availableCalendarsOptions']"
                          :key="calendar.value"
                          :label="calendar.label"
                          :value="calendar.value"
                          :disabled="calendar.disabled"
                        />
                      </ElSelect>
                    </div>
                    <div
                      v-else
                      class="col-2 offset-2"
                    >
                      <SdSpinner class="position-absolute mt-4 working" />
                    </div>
                  </template>
                  <template v-if="$store.getters['Auth/hasInitialCalendarConnection']">
                    <div class="col-12 text-gray-dark pt-2">
                      Currently connected to the <strong>{{ connectedCalendarEmail }}</strong> calendar
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-12 col-md-8">
                      Connect your calendar to your Showdigs account.<br>
                      This will be used to help ensure tours are not scheduled for you
                      when your calendar shows that you are busy.
                    </div>
                    <div
                      class="col-12 col-md-2"
                      :class="$viewport.lt.md ? 'p-3' : ''"
                    >
                      <ElButton
                        type="primary"
                        :class="$viewport.lt.md ? 'w-100' : ''"
                        :size="$viewport.lt.md ? '' : 'medium'"
                        :disabled="state.Calendar.waitingForRegistrationResponse"
                        :loading="state.Calendar.waitingForRegistrationResponse"
                        @click="connectCalendar"
                      >
                        <i class="sdicon-calendar-check-o" />
                        Connect my calendar
                      </ElButton>
                    </div>
                  </template>
                </el-tab-pane>
              </SdConditionallyBumpChildren>
              <div class="col-12 d-md-flex d-none">
                <ElDivider />
              </div>
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-touch:swipe="swipeHandler"
                  label="Password"
                  class="row"
                >
                  <div
                    v-if="$viewport.gt.md"
                    class="col-12"
                  >
                    <h2>
                      Password
                    </h2>
                  </div>

                  <div class="col-12 col-md-6">
                    <ElFormItem prop="current_password">
                      <SdFloatLabel for-input="current_password">
                        <ElInput
                          id="current_password"
                          v-model="payload.current_password"
                          :type="isCurrentPasswordVisibleRef ? 'text' : 'password'"
                          placeholder="Current password"
                        >
                          <template slot="suffix">
                            <i
                              class="pointer px-1"
                              :class="
                                isCurrentPasswordVisibleRef
                                  ? 'sdicon-eye-slash'
                                  : 'sdicon-eye'
                              "
                              @click="triggerPasswordVisibility('isCurrentPasswordVisibleRef')"
                            />
                          </template>
                        </ElInput>
                      </SdFloatLabel>
                    </ElFormItem>
                  </div>
                  <div class="col-12 col-md-6">
                    <ElFormItem prop="password">
                      <SdFloatLabel for-input="password">
                        <ElInput
                          id="password"
                          v-model="payload.password"
                          :type="isPasswordVisibleRef ? 'text' : 'password'"
                          placeholder="New password"
                        >
                          <template slot="suffix">
                            <i
                              class="pointer px-1"
                              :class="
                                isPasswordVisibleRef
                                  ? 'sdicon-eye-slash'
                                  : 'sdicon-eye'
                              "
                              @click="triggerPasswordVisibility('isPasswordVisibleRef')"
                            />
                          </template>
                        </ElInput>
                      </SdFloatLabel>
                    </ElFormItem>
                  </div>
                </el-tab-pane>
              </SdConditionallyBumpChildren>
              <div class="col-12 d-md-flex d-none">
                <ElDivider />
              </div>
              <div
                v-if="$viewport.gt.md"
                class="col-12"
              >
                <h2>Notification Settings</h2>
              </div>
              <SdConditionallyBumpChildren :bump-if="$viewport.lt.md">
                <el-tab-pane
                  v-touch:swipe="swipeHandler"
                  label="Notifications"
                  class="row"
                >
                  <div class="col-12">
                    <div class="row align-items-center">
                      <div class="col-md col-12">
                        <SdFloatLabel for-input="daily_report_notifications">
                          <ElSelect
                            v-model="payload.settings.daily_report_notifications.value"
                            placeholder="Send daily activity report"
                          >
                            <ElOption
                              v-for="item in notificationOptionsRef"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </SdFloatLabel>
                      </div>
                      <div
                        class="col-md-3 col-12 text-md-center"
                        :class="{ 'text-gray': payload.settings.daily_report_notifications.value === 'none' }"
                      >
                        Email only
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center">
                      <div class="col-md col-12">
                        <SdFloatLabel for-input="weekly_report_notifications">
                          <ElSelect
                            id="weekly_report_notifications"
                            v-model="payload.settings.weekly_report_notifications.value"
                            placeholder="Send weekly property report"
                          >
                            <ElOption
                              v-for="item in notificationOptionsRef"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </SdFloatLabel>
                      </div>
                      <div
                        class="col-md-3 col-12 text-md-center"
                        :class="{ 'text-gray': payload.settings.weekly_report_notifications.value === 'none' }"
                      >
                        Email only
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center">
                      <div class="col-md col-12">
                        <SdFloatLabel for-input="property_updates_notifications">
                          <ElSelect
                            id="property_updates_notifications"
                            v-model="payload.settings.property_updates_notifications.value"
                            placeholder="Send tour reports and related updates"
                          >
                            <ElOption
                              v-for="item in notificationOptionsRef"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </SdFloatLabel>
                      </div>
                      <div
                        class="col-md-3 col-12 text-md-center"
                        :class="{ 'text-gray': payload.settings.property_updates_notifications.value === 'none' }"
                      >
                        Email only
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row align-items-center">
                      <div class="col-md col-12">
                        <SdFloatLabel for-input="tour_scheduled_notifications">
                          <ElSelect
                            id="tour_scheduled_notifications"
                            v-model="payload.settings.tour_scheduled_notifications.value"
                            placeholder="Send updates on tours scheduled for today"
                          >
                            <ElOption
                              v-for="item in notificationOptionsRef"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </SdFloatLabel>
                      </div>
                      <div
                        class="col-md-3 col-12 text-md-center"
                        :class="{ 'opacity-25': payload.settings.tour_scheduled_notifications.value === 'none' }"
                      >
                        <ElCheckboxGroup v-model="payload.settings.tour_scheduled_notifications.channels">
                          <el-checkbox label="email">
                            Email
                          </el-checkbox>
                          <el-checkbox label="sms">
                            Text
                          </el-checkbox>
                        </ElCheckboxGroup>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="businessSourceRef && businessSourceRef.synced_at"
                    class="col-12"
                  >
                    <div class="row align-items-center">
                      <div class="col-md col-12">
                        <SdFloatLabel for-input="website_vacancies_updates_notifications">
                          <ElSelect
                            id="website_vacancies_updates_notifications"
                            v-model="payload.settings.website_vacancies_updates_notifications.value"
                            placeholder="Send notifications when new vacancies are imported from the website"
                          >
                            <ElOption
                              v-for="item in notificationOptionsWithoutOwnRef"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </SdFloatLabel>
                      </div>
                      <div
                        class="col-md-3 col-12 text-md-center"
                        :class="{ 'text-gray': payload.settings.website_vacancies_updates_notifications.value === 'none' }"
                      >
                        Email only
                      </div>
                    </div>
                  </div>
                  <div class="col-12 align-items-center">
                    <div class="row align-items-center">
                      <div class="col-md col-12">
                        <SdFloatLabel for-input="inspection_updates">
                          <ElSelect
                            id="inspection_updates"
                            v-model="payload.settings.inspection_notifications.value"
                            placeholder="Send condition report updates"
                          >
                            <ElOption
                              v-for="item in inspectionNotificationOptionsRef"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </SdFloatLabel>
                      </div>
                      <div
                        class="col-md-3 col-12 text-md-center"
                        :class="{ 'text-gray': payload.settings.inspection_notifications.value === 'none' }"
                      >
                        Email only
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </sdconditionallybumpchildren>
            </el-tabs>
          </sdconditionallybumpchildren>
        </elform>
        <ElDivider />
        <div
          class="col-12 justify-content-end d-flex"
          :class="$viewport.lt.md ? 'p-3' : ''"
        >
          <ElButton
            type="primary"
            :class="$viewport.lt.md ? 'w-100' : ''"
            :loading="formState.loading"
            :size="$viewport.lt.md ? '' : 'medium'"
            @click="updateUser"
          >
            Update
          </ElButton>
        </div>
      </div>
    </div>
  </SdPage>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';

import { nylasCalendarAuthLink } from '@/utils/NylasCalendarUtil';
import SdFloatLabel from '@/components/common/form/SdFloatLabel';
import ProfileAvatarUploadField from '@/components/profile/ProfileAvatarUploadField';
import Regex from '@/constants/Regex';
import get from 'lodash.get';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import ConfirmRemoveCalendarIntegrationDialog from '@/components/profile/ConfirmRemoveCalendarIntegrationDialog';
import SdSpinner from '@/components/common/SdSpinner';

const regexConstants = Regex;
export default {
  components: {
    ConfirmRemoveCalendarIntegrationDialog, SdFloatLabel, ProfileAvatarUploadField, SdSpinner,
  },
  setup(props, context) {
    const { $store, $route, $router } = context.root;

    const validateCurrentPassword = (rule, value, callback) => {
      if (value === '' && payload.password !== '') {
        callback(new Error('Current Password is required'));
      } else {
        callback();
      }
    };
    const validateNewPassword = (rule, value, callback) => {
      if (value !== '' && value.length < 6) {
        callback(new Error('Password length should be at least 6 symbols'));
      } else {
        callback();
      }
    };
    const formRules = getFormRules();
    const formState = reactive(defaultFormState);
    const user = $store.state.Auth.user;
    const state = $store.state;
    const isPasswordVisibleRef = ref(false);
    const activeTabRef = ref('0');
    const isCurrentPasswordVisibleRef = ref(false);
    const notificationOptionsRef = ref(defaultNotificationOptions);
    const notificationOptionsWithoutOwnRef = ref(defaultNotificationOptionsWithoutOwn);
    const inspectionNotificationOptionsRef = ref(defaultInspectionNotificationsOptions);
    const businessSourceRef = ref(null);
    const rulesRef = ref(formRules);
    const selectedCalendars = ref([]);
    const calendarWorking = ref(false);
    const connectedCalendarEmail = computed(() => get($store.state.Auth, 'user.connected_calendar_email'));
    const calendarData = reactive({
      timeoutHolder: null,
      working: false,
    });
    const payload = reactive({
      first_name: user.first_name,
      last_name: user.last_name,
      public_phone_number: user.public_phone_number,
      public_phone_number_extension: user.public_phone_number_extension,
      phone_number: user.phone_number,
      phone_number_extension: user.phone_number_extension,
      email: user.email,
      current_password: '',
      password: '',
      settings: {
        new_inquiry_notifications: { ...user.settings.new_inquiry_notifications },
        daily_report_notifications: { ...user.settings.daily_report_notifications },
        weekly_report_notifications: { ...user.settings.weekly_report_notifications },
        property_updates_notifications: { ...user.settings.property_updates_notifications },
        tour_scheduled_notifications: { ...user.settings.tour_scheduled_notifications },
        inspection_notifications: { ...user.settings.inspection_notifications },
        website_vacancies_updates_notifications: user.settings.website_vacancies_updates_notifications
          ? { ...user.settings.website_vacancies_updates_notifications }
          : {
          value: 'all',
          channels: ['email'],
        },
      },
    });
    init();
    return {
      get,
      swipeHandler,
      activeTabRef,
      payload,
      formState,
      isCurrentPasswordVisibleRef,
      isPasswordVisibleRef,
      triggerPasswordVisibility,
      notificationOptionsRef,
      notificationOptionsWithoutOwnRef,
      inspectionNotificationOptionsRef,
      businessSourceRef,
      validateCurrentPassword,
      rulesRef,
      updateUser,
      connectCalendar,
      disconnectCalendar,
      confirmDisconnectCalendar,
      connectedCalendarEmail,
      calendarWorking,
      user,
      uploadUserAvatar,
      calendarData,
      selectedCalendars,
      onCalendarSelectionUpdate,
      state,
    };

    async function loadAvailableCalendarsOptions() {
      if (!$store.getters['Auth/hasInitialCalendarConnection']) {
        return;
      }

      if ($store.getters['Calendar/selectedCalendarsLength']) {
        selectedCalendars.value = $store.getters['Calendar/selectedCalendars'];
      } else {
        selectedCalendars.value = $store.getters['Calendar/selectedCalendars'].map((calendar) => calendar.id);
      }

      await retryUntilCalendarsLoaded();
    }

    async function init() {
      await loadAvailableCalendarsOptions();

      try {
        businessSourceRef.value = await $store.dispatch('BusinessSource/index');
      } catch (e) {
      }

      if ($route.query.code && $route.query.state) {
        await completeCalendarIntegration();
      }
    }

    async function onCalendarSelectionUpdate(calendarIds) {
      if (calendarData.timeoutHolder) {
        clearTimeout(calendarData.timeoutHolder);
      }
      calendarData.timeoutHolder = setTimeout(async () => {
        calendarData.working = true;
        try {
          await $store.dispatch('Calendar/update', calendarIds);
          showSuccessNotify(context, 'Calendar selection updated.');
          $store.commit('Calendar/setSelectedCalendars', calendarIds);
        } catch (error) {
          showErrorNotify(context, error.message || 'Error while updating your calendar selection.');
          await loadAvailableCalendarsOptions();
        } finally {
          calendarData.working = false;
        }
      }, 2000);
    }

    function swipeHandler(direction) {
      if (direction === 'left') {
        activeTabRef.value = activeTabRef.value !== '2' ? (+activeTabRef.value + 1).toString() : '2';
      } else if (direction === 'right') {
        activeTabRef.value = activeTabRef.value !== '0' ? (+activeTabRef.value - 1).toString() : '0';
      }
    }

    async function uploadUserAvatar(imgObj) {
      if (imgObj && imgObj.size) {
        const formData = new FormData();
        formData.append('avatar_file', imgObj);
        await $store.dispatch('Auth/uploadAvatar', formData);
        showSuccessNotify(context, 'Profile picture was updated');
      }
    }

    async function connectCalendar() {
      const url = nylasCalendarAuthLink(context);
      window.location.href = url;
    }

    async function completeCalendarIntegration() {
      try {
        const query = { ...$route.query };
        delete query.code;
        delete query.state;
        $router.replace({ query });
        await $store.dispatch('Calendar/registerIntegration', {
          code: $route.query.code,
        });
        await retryUntilCalendarsLoaded();
        $store.commit('Calendar/setWaitingForRegistrationResponse', false);
        showSuccessNotify(context, 'Calendar integration completed.');
        calendarWorking.value = false;
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }

    async function retryUntilCalendarsLoaded() {
      calendarData.working = true;
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      let tries = 0;
      const maxTries = 3;
      let data;
      while (!$store.getters['Calendar/selectedCalendars'].length && (tries < maxTries)) {
        try {
          tries++;
          data = await $store.dispatch('Calendar/load');
          await $store.dispatch('Auth/setMeState', data);
        } catch (error) {
          if (tries === maxTries) {
            const tenSeconds = 10000;
            showErrorNotify(context, error.message, { duration: tenSeconds });
          }
        }

        if (!data?.selectedCalendars) {
          await sleep(2000);
        }
      }
      calendarData.working = false;
    }

    function disconnectCalendar() {
      this.$refs.confirmRemoveCalendarIntegrationDialog.open(user.email);
    }

    async function confirmDisconnectCalendar() {
      calendarWorking.value = true;
      try {
        const data = await $store.dispatch('Calendar/disconnectIntegration');
        await $store.dispatch('Auth/setMeState', data);
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        calendarWorking.value = false;
      }
    }

    async function updateUser() {
      formState.loading = true;
      try {
        await context.refs.form.validate();
        if (payload.password === '' || payload.password === null) {
          delete payload.password; // not sending password if empty
        }
        await context.root.$store.dispatch('Auth/updateUser', payload);
        await context.root.$store.dispatch('Auth/me');
        formState.loading = false;
        payload.password = '';
        showSuccessNotify(context, 'Settings were updated');
      } catch (error) {
        formState.loading = false;
        payload.password = '';
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    function triggerPasswordVisibility(passRef) {
      return this[passRef] = !this[passRef];
    }

    function getFormRules() {
      return {
        first_name: [
          {
            required: true,
            type: 'string',
            message: 'First name is required',
          },
        ],
        last_name: [
          {
            required: true,
            type: 'string',
            message: 'Last name is required',
          },
        ],
        email: [
          {
            required: true,
            type: 'email',
            message: 'Email name is required',
          },
        ],
        current_password: [
          {
            validator: validateCurrentPassword,
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            validator: validateNewPassword,
            trigger: ['blur', 'change'],
          },
        ],
        phone_number: [
          {
            required: true,
            message: 'Phone number is required',
          },
          {
            pattern: regexConstants.phone_number,
            message: 'Enter a valid phone number',
          },
        ],
        phone_number_extension: [
          {
            pattern: regexConstants.numeric,
            max: 5,
            message: 'Enter a numeric extension',
          },
        ],
        public_phone_number: [
          {
            pattern: regexConstants.phone_number,
            message: 'Enter a valid phone number',
          },
        ],
        public_phone_number_extension: [
          {
            pattern: regexConstants.numeric,
            max: 5,
            message: 'Enter a numeric extension',
          },
        ],
      };
    }
  },
};
const defaultFormState = {
  loading: false,
};
const defaultNotificationOptions = [
  {
    label: 'For all properties',
    value: 'all',
  },
  {
    label: "Only where I'm the listing contact",
    value: 'own',
  },
  {
    label: "Don't send at all",
    value: 'none',
  },
];
const defaultInspectionNotificationsOptions = [
  {
    label: 'For all reports',
    value: 'all',
  },
  {
    label: 'Only for reports I ordered',
    value: 'own',
  },
  {
    label: "Don't send at all",
    value: 'none',
  },
];
const defaultNotificationOptionsWithoutOwn = [...defaultNotificationOptions];
defaultNotificationOptionsWithoutOwn.splice(defaultNotificationOptionsWithoutOwn.findIndex((option) => option.value === 'own'), 1);
</script>
<style lang="scss">
#settings-page {
  .el-tag__close {
    display: none;
  }

  .working {
    z-index: 2;
  }

  &.mobile {
    .settings-page-row {
      margin: 0rem -1rem 0rem -1rem;
    }

    .el-tabs__content {
      padding: 1rem;
    }
  }

  .el-checkbox {
    text-align: center;

    &__label {
      display: block;
      padding: 0;
    }
  }

  .el-form-item__content {
    line-height: inherit;
  }
}
</style>
