<template>
  <ElDialog
    id="confirm-remove-calendar-integration-dialog"
    :center="true"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <div class="text-center justify-content-center">
      <div class="font-21 font-weight-strong my-3 text-center">
        Disconnect calendar: {{ uiFlags.email }}
      </div>
      <div class="text-center">
        Are you sure you want to disconnect your calendar?
      </div>
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-12 col-md-5">
          <ElButton
            type="primary"
            size="medium"
            class="justify-center mb-3 mt-5"
            @click="uiFlags.isVisible = false;"
          >
            No
          </ElButton>
        </div>
        <div class="col-12 col-md-5">
          <ElButton
            type="primary"
            size="medium"
            class="justify-center mb-3 mt-5"
            @click="uiFlags.isVisible = false; $emit('confirm')"
          >
            Yes
          </ElButton>
        </div>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';

export default {
  name: 'ConfirmRemoveCalendarIntegrationDialog',
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
      email: null,
    });

    return {
      uiFlags,
      open,
    };

    function open(email) {
      uiFlags.email = email;
      uiFlags.isVisible = true;
    }
  },
};
</script>

<style lang="scss">
</style>
